:root {
  --toastify-toast-background: #000;
  --toastify-text-color-error: #000;
}

* {
  margin: 0;
  padding: 0;
  /* width */
}

@media print {
  html,
  body {
    display: none;
  }
}

/* https://fkhadra.github.io/react-toastify/how-to-style/ */
.Toastify__toast--warning {
  background: blue !important;
}
.Toastify__toast {
  background: #ffffff !important;
  box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.05),
    4px 4px 10px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar {
  width: 8px;
  background: #fafff4;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 80px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 80px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b9b9b9;
}
