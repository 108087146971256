@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

body {
  font-family: "Noto Sans", sans-serif;
}
.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #e0e0e0;
}

.ql-toolbar .ql-fill {
  fill: #e0e0e0;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #e0e0e0;
}

.ql-toolbar .ql-picker-options {
  background-color: #013a4f;
}

.ql-formats {
  border-left: 1px solid rgba(149, 244, 0, 0.3);
}

.ql-formats:first-child {
  border-left: none;
}

.quill .ql-container {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 0.1px solid rgba(194, 194, 194, 0.4);
  outline: none;
  background-color: white;
}
